<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title> Adicionar novo fluxo </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col cols="6">
                  <v-text-field label="Nome do Arquivo"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="Arquivos"
                    counter
                    multiple
                    show-size
                    truncate-length="15"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea label="Descrição"></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select label="Participantes" :items="['Marcos', 'Alysson']"></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="success"> Salvar Fluxo </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
